export const enum AnalyticsEvent {
  //InitApplication
  InitApplication = 'InitApplication / User initialized the application',

  //Integrations
  OpenIntegrationsOverview = 'Integrations / Open Integrations Overview',

  //Report
  OpenReport = 'Report / Open report',

  //
  OpenDemo = 'Demo / Open demo',
}
export type AnalyticsProperties = Record<string, unknown>
