import { useCallback, useState } from 'react'

import { RequestStatus } from 'core/domain/Request'
import { RequestType } from 'demo/domain/RequestType'
import { requestZendesk as requestZendeskImpl } from 'demo/use-cases/tryRequestZendesk'

export function useRequestZendesk() {
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Initial)

  const requestZendesk = useCallback(
    async (requestType: RequestType, name: string, company: string, email: string, phone:string, config?: AbortController) => {
      setStatus(RequestStatus.Pending)

      try {
        await requestZendeskImpl(requestType, name, company, email, phone, config)

        setStatus(RequestStatus.Done)
      } catch (error) {
        if (config?.signal.aborted) return

        setStatus(RequestStatus.Error)
      }
    },
    []
  )

  const isSendingRequest = status === RequestStatus.Pending
  const hasError = status === RequestStatus.Error
  const hasSuccess = status === RequestStatus.Done

  return {
    isSendingRequest,
    hasError,
    hasSuccess,
    requestZendesk,
  }
}
