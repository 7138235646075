import { useCallback, useState } from 'react'

import { RequestStatus } from 'core/domain/Request'
import { PBIReport, PBIToken } from 'managed-analytics/domain/PBIReport'
import { getPBIToken as getTokenPBI } from 'managed-analytics/use-cases/getPBIToken'

type State = {
  pbiToken?: PBIToken
  status: RequestStatus
}

export function usePBIToken() {
  const [state, setState] = useState<State>({
    status: RequestStatus.Initial,
  })

  const { status, pbiToken } = state

  const isLoading = status === RequestStatus.Pending

  const getPBIToken = useCallback(async (report?: PBIReport, config?: AbortController) => {
    if (!report) return

    setState({ status: RequestStatus.Pending })

    try {
      const pbiToken = await getTokenPBI(report, config)

      setState({ status: RequestStatus.Done, pbiToken })
    } catch (error) {
      if (config?.signal.aborted) return

      setState({ status: RequestStatus.Error })

      throw error
    }
  }, [])

  const resetPBIToken = useCallback(async () => {
    setState({ status: RequestStatus.Initial })
  }, [])

  return {
    isLoading,
    status,
    pbiToken,
    getPBIToken,
    resetPBIToken,
  }
}
