import { HttpClientConfig, type HttpClientInterface } from 'core/infra/http/HttpClient'

import { mapApiToIntegration, RawIntegration } from './IntegrationMapper'

export type IntegrationRepositoryDependencies = {
  httpClient: HttpClientInterface
}

export class IntegrationRepository {
  private httpClient: HttpClientInterface

  constructor({ httpClient }: IntegrationRepositoryDependencies) {
    this.httpClient = httpClient
  }

  async getIntegration(config?: HttpClientConfig) {
    const endpoint = '/integration'

    const { data } = await this.httpClient.get<Array<RawIntegration>>(endpoint, config)

    try {
      // TODO: remove this. Change at runtime until BE is modified
      const isBeReady = true

      // @ts-expect-error temp fix until BE is modified
      return mapApiToIntegration(isBeReady ? data : JSON.parse(data.integration))
    } catch (error) {
      throw new Error('Failed to map API to integration')
    }
  }
}
