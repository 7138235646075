import { useCallback, useRef } from 'react'

import pkceChallenge from 'pkce-challenge'
import { useLocation, useNavigate } from 'react-router-dom'

import { useSettings } from '@loadsmart/react-frontend-settings'

import { CODE_VERIFIER_STORAGE_KEY, TOKEN_STORAGE_USER_KEY } from 'auth/domain/User'
import { useToken } from 'auth/ui/hooks/useToken'
import { ANALYTICS_PORTAL_URL, BIP_CLIENT_ID, BIP_URL } from 'core/infra/environment'
import { LocalStorageClient } from 'core/infra/storage/LocalStorageClient'

const CLIENT_ID = BIP_CLIENT_ID
const LOGIN_URL = `${BIP_URL}/auth/authorize/`
const LOGIN_REDIRECT_URL = `${ANALYTICS_PORTAL_URL}/login-callback`
const LOGOUT_URL = `${BIP_URL}/auth/logout/`
const LOGOUT_REDIRECT_URI = `${ANALYTICS_PORTAL_URL}`

const storage = new LocalStorageClient()

interface Oauth2FlowProps {
  onLoginSuccess?: () => void
  onLoginFailure?: (error: Error) => void
}

const useOauth2Flow = (props?: Oauth2FlowProps) => {
  const { onLoginSuccess, onLoginFailure } = props ?? {}
  const location = useLocation()
  const navigate = useNavigate()

  const { fetchToken } = useToken()
  const {
    values: [isLoginThroughBIPEnabled],
  } = useSettings(['flags.ENABLE_LOGIN_THROUGH_BIP'])

  // This ref is used to make sure the 'fetchTokens' call is only made once.
  const didFetchTokens = useRef(false)

  const isLoggedIn = useCallback(async () => {
    const accessToken = storage.get(TOKEN_STORAGE_USER_KEY)
    return Promise.resolve(Boolean(accessToken))
  }, [])

  const logIn = useCallback(() => {
    pkceChallenge()
      .then(({ code_verifier, code_challenge }) => {
        storage.set(CODE_VERIFIER_STORAGE_KEY, code_verifier)

        const params = new URLSearchParams({
          code_challenge: code_challenge,
          response_type: 'code',
          client_id: CLIENT_ID,
          redirect_uri: LOGIN_REDIRECT_URL,
          code_challenge_method: 'S256',
        })

        window.location.replace(`${LOGIN_URL}?${params.toString()}`)
      })
      .catch((error: Error) => {
        console.error(error)
        throw error
      })
  }, [])

  const handleLogInCallback = useCallback(() => {
    const params = new URLSearchParams(location.search)
    const authCode = params.get('code')
    const codeVerifier = storage.get<string>(CODE_VERIFIER_STORAGE_KEY)

    if (!(authCode && codeVerifier)) {
      onLoginFailure?.(new Error('Missing auth code or code verifier'))
      return
    }

    if (!didFetchTokens.current) {
      didFetchTokens.current = true

      fetchToken(authCode, codeVerifier)
        .then(() => {
          onLoginSuccess?.()
        })
        .catch((error: Error) => {
          onLoginFailure?.(error)
        })
    }
  }, [])

  const logOut = useCallback(() => {
    if (isLoginThroughBIPEnabled) {
      const params = new URLSearchParams({
        client_id: CLIENT_ID,
        post_logout_redirect_uri: LOGOUT_REDIRECT_URI,
      })
      window.location.replace(`${LOGOUT_URL}?${params.toString()}`)
    } else {
      navigate('/')
    }
  }, [isLoginThroughBIPEnabled])

  return { isLoggedIn, logIn, logOut, handleLogInCallback }
}

export default useOauth2Flow
