import axios, {
  type AxiosError,
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosResponse,
  type InternalAxiosRequestConfig,
} from 'axios'

import { type UserRepository } from 'auth/infra/UserRepository'

export type HttpClientConfig = AxiosRequestConfig
export type HttpClientResponse<T> = AxiosResponse<T>
export type HttpClientError<T> = AxiosError<T>

export interface HttpClientInterface {
  get<T>(url: string, config?: HttpClientConfig): Promise<HttpClientResponse<T>>
  post<T, U>(url: string, data?: T, config?: HttpClientConfig): Promise<HttpClientResponse<U>>
  put<T, U>(url: string, data?: T, config?: HttpClientConfig): Promise<HttpClientResponse<U>>
  patch<T, U>(url: string, data?: T, config?: HttpClientConfig): Promise<HttpClientResponse<U>>
  delete<T>(url: string, config?: HttpClientConfig): Promise<HttpClientResponse<T>>
}

export type RequestInterceptor = (
  config: InternalAxiosRequestConfig
) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>

export type HttpClientOptions = Partial<{
  requestInterceptor: RequestInterceptor | null
}>

export class HttpClient implements HttpClientInterface {
  private client: AxiosInstance
  static requestInterceptor: RequestInterceptor

  constructor(baseURL?: string, options: HttpClientOptions = {}) {
    this.client = axios.create({
      baseURL,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/json',
      },
    })

    const { requestInterceptor = HttpClient.requestInterceptor } = options

    if (typeof requestInterceptor === 'function') {
      this.client.interceptors.request.use(requestInterceptor)
    }
  }

  static setup(interceptors: { request?: RequestInterceptor } = {}) {
    if (interceptors.request) {
      HttpClient.requestInterceptor = interceptors.request
    }
  }

  get<T>(url: string, config?: HttpClientConfig): Promise<HttpClientResponse<T>> {
    return this.client.get<T>(url, config)
  }

  post<T, U>(url: string, data?: T, config?: HttpClientConfig): Promise<HttpClientResponse<U>> {
    return this.client.post<T, HttpClientResponse<U>>(url, data, config)
  }

  put<T, U>(url: string, data?: T, config?: HttpClientConfig): Promise<HttpClientResponse<U>> {
    return this.client.put<T, HttpClientResponse<U>>(url, data, config)
  }

  patch<T, U>(url: string, data?: T, config?: HttpClientConfig): Promise<HttpClientResponse<U>> {
    return this.client.patch<T, HttpClientResponse<U>>(url, data, config)
  }

  delete<T>(url: string, config?: HttpClientConfig): Promise<HttpClientResponse<T>> {
    return this.client.delete<T>(url, config)
  }
}

type TokenInterceptorParams = { userRepository: UserRepository }

export function createTokenInterceptor({ userRepository }: TokenInterceptorParams) {
  return function tokenInterceptor(config: HttpClientConfig) {
    const token = userRepository.getLocalToken()

    if (token && config?.headers) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  } as RequestInterceptor
}
