import { HttpClientConfig, type HttpClientInterface } from 'core/infra/http/HttpClient'
import { RequestType } from 'managed-analytics/domain/RequestType'

export type ZendeskRepositoryDependencies = {
  httpClient: HttpClientInterface
}

export class ZendeskRepository {
  private httpClient: HttpClientInterface

  constructor({ httpClient }: ZendeskRepositoryDependencies) {
    this.httpClient = httpClient
  }

  async requestZendesk(requestType: RequestType, description: string, reportName: string, config?: HttpClientConfig) {
    const endpoint = '/zendeskrequest'
    await this.httpClient.post(endpoint, { type: requestType, reportName: reportName, description }, config)
  }
}
