import React, { useRef } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report } from 'powerbi-client';
import { PBIReport } from 'managed-analytics/domain/PBIReport';
import { Layout, SpinnerWheel } from '@loadsmart/miranda-react'

import {
    StyledPBIEmbedWrapper,
    StyledPageContainerDiv
  } from './Report.styles'

interface ReportComponentProps {
  selectedReport: PBIReport;
  accessToken : string | undefined;
  isReportLoading : boolean;
  isTokenLoading : boolean;
}

const ReportComponent: React.FC<ReportComponentProps> = ({ selectedReport, accessToken, isReportLoading, isTokenLoading }) => {

  const currentReport = useRef<Report>();

  return (
    <StyledPBIEmbedWrapper>
    { isReportLoading || isTokenLoading &&(
        <StyledPageContainerDiv style={{ height: '70vh' }}>
            <Layout.Group align="center" justify="center" style={{ flex: 1 }}>
            <SpinnerWheel size="32px" />
            </Layout.Group>
        </StyledPageContainerDiv>
    )}
    { !isReportLoading && !isTokenLoading && (
        <PowerBIEmbed
            embedConfig={{
                accessToken: accessToken,
                groupId: selectedReport.groupId,
                id: selectedReport.reportId,
                tokenType: models.TokenType.Embed,
                type: 'report',
                settings: {
                  panes: {
                      pageNavigation: {
                          visible: true,
                          position: models.PageNavigationPosition.Left
                      },
                  }
                }
            }}
                cssClassName="pbi-report-class"
                getEmbeddedComponent={embeddedReport => {
                currentReport.current = embeddedReport as Report
            }}
        />
    )}
    </StyledPBIEmbedWrapper>
  );
};

export default ReportComponent;