import { BASE_API_URL } from 'core/infra/environment'
import { HttpClient } from 'core/infra/http/HttpClient'
import { SentryWrapper } from 'core/infra/sentry/SentryWrapper'
import { LocalStorageClient } from 'core/infra/storage/LocalStorageClient'
import { StorageInterface } from 'core/infra/storage/StorageInterface'
import { isFuture } from 'core/utils/date'
import { PBIReport, PBIToken } from 'managed-analytics/domain/PBIReport'
import { PBIRepository } from 'managed-analytics/infra/PBIRepository'

export type GetPBITokenFactoryDependencies = {
  pbiRepository: PBIRepository
  storageRepository: StorageInterface
}

export function getPBITokenFactory({
  pbiRepository,
  storageRepository,
}: GetPBITokenFactoryDependencies) {
  return async function (report: PBIReport, config?: { signal?: AbortSignal }) {
    try {
      const pbiTokenKey = `pbi-token-${report.reportId}`

      // Check if token is already present in local storage
      const storedToken = storageRepository.get<PBIToken>(pbiTokenKey)

      if (storedToken && isFuture(new Date(storedToken.expiresIn))) {
        return storedToken
      }

      const newToken = await pbiRepository.getPbiToken(report, config)

      storageRepository.set(pbiTokenKey, newToken)

      return newToken
    } catch (error) {
      SentryWrapper.log(error as Error)
      throw error
    }
  }
}

export function getPBIToken(report: PBIReport, config?: { signal?: AbortSignal }) {
  const httpClient = new HttpClient(BASE_API_URL)
  const pbiRepository = new PBIRepository({ httpClient })
  const storageRepository = new LocalStorageClient()

  const get = getPBITokenFactory({ pbiRepository, storageRepository })

  return get(report, config)
}
