import { TOKEN_STORAGE_USER_KEY } from 'auth/domain/User'
import { SentryWrapper } from 'core/infra/sentry/SentryWrapper'
import { type StorageInterface } from 'core/infra/storage/StorageInterface'

type UserRepositoryDeps = {
  storage: StorageInterface
}

export type UserRepository = {
  getLocalToken(): string | undefined
}

export function createUserRepository({ storage }: UserRepositoryDeps): UserRepository {
  function getLocalToken() {
    try {
      return storage.get<string>(TOKEN_STORAGE_USER_KEY)
    } catch (error) {
      SentryWrapper.log(error as Error)
      throw error
    }
  }

  return { getLocalToken }
}
