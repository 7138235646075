import { useState } from 'react';
import { Dialog, Field } from '@loadsmart/miranda-react';
import { StyledTextField } from './TryNowZendeskDialog.styles';

interface ZendeskDialogProps {
  open: boolean;
  disabled: boolean;
  onSendRequest: (name: string, company: string, email: string, phone: string) => void;
  onCancelRequest: () => void;
}

const ZendeskDialog = ({
  open,
  disabled,
  onSendRequest,
  onCancelRequest
}: ZendeskDialogProps) => {
  const [name, setName] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone: string): boolean => {
    const phoneRegex = /^(\+?\d{1,2}\s?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/;
    return phoneRegex.test(phone);
  };

  const handleSendRequest = async () => {
    const isEmailValid = validateEmail(email);
    const isPhoneValid = validatePhone(phone);

    if (!isEmailValid) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }

    if (!isPhoneValid) {
      setPhoneError('Invalid phone number');
    } else {
      setPhoneError('');
    }

    if (name && company && isEmailValid && isPhoneValid) {
      onSendRequest(name, company, email, phone);
      setName('');
      setCompany('');
      setEmail('');
      setPhone('');
    } else {
      alert('Please fill in all fields with valid information before submitting.');
    }
  };

  const handleClose = () => {
    onCancelRequest();
    setName('');
    setCompany('');
    setEmail('');
    setPhone('');
    setEmailError('');
    setPhoneError('');
  };

  const isDisabled = disabled || name.length <= 0 || company.length <= 0 || email.length <= 0 || phone.length <= 0;

  return (
    <Dialog open={open} size="small" variant="neutral" onClose={handleClose}>
      <Dialog.Header>
        We'll be in touch
        <Dialog.Close />
      </Dialog.Header>
      <Dialog.Body>
        <Field required>
          <Field.Label id="nameLabel">Name</Field.Label>
          <StyledTextField
            aria-labelledby="nameLabel"
            aria-required="true"
            onInput={(event) => {
              if (event.target) {
                const inputElement = event.target as unknown as HTMLInputElement;
                setName(inputElement.value);
              }
            }}
          />
        </Field>
        <Field required>
          <Field.Label id="companyLabel">Company</Field.Label>
          <StyledTextField
            aria-labelledby="companyLabel"
            aria-required="true"
            onInput={(event) => {
              if (event.target) {
                const inputElement = event.target as unknown as HTMLInputElement;
                setCompany(inputElement.value);
              }
            }}
          />
        </Field>
        
        <Field required>  
          <Field.Label id="emailLabel">Email</Field.Label>
          <StyledTextField
            aria-required="true"
            aria-labelledby="emailLabel"
            onInput={(event) => {
              if (event.target) {
                const inputElement = event.target as unknown as HTMLInputElement;
                const value = inputElement.value;
                setEmail(value);
                setEmailError(validateEmail(value) ? '' : 'Invalid email address');
              }
            }}
          />
          {emailError && <span style={{ color: 'red' }}>{emailError}</span>}
        </Field>
        
        <Field required>
          <Field.Label id="phoneLabel">Phone</Field.Label>
          <StyledTextField
            aria-labelledby="phoneLabel"
            aria-required="true"
            onInput={(event) => {
              if (event.target) {
                const inputElement = event.target as unknown as HTMLInputElement;
                const value = inputElement.value;
                setPhone(value);
                setPhoneError(validatePhone(value) ? '' : 'Invalid phone number');
              }
            }}
          />
          {phoneError && <span style={{ color: 'red' }}>{phoneError}</span>}
        </Field>
      </Dialog.Body>
      <Dialog.Footer></Dialog.Footer>
      <Dialog.Actions>
        <Dialog.ActionTertiary onClick={handleClose} disabled={isDisabled}>
          Cancel
        </Dialog.ActionTertiary>
        <Dialog.ActionPrimary onClick={handleSendRequest} disabled={isDisabled}>
          Send request
        </Dialog.ActionPrimary>
      </Dialog.Actions>
    </Dialog>
  );
};

export default ZendeskDialog;
