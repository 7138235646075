import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { Layout, SpinnerWheel, Text } from '@loadsmart/miranda-react'

import useOauth2Flow from 'auth/ui/hooks/useOauth2'

const LoginThroughBIPCallback = () => {
  const navigate = useNavigate()

  function onLoginSuccess() {
    navigate('/analytics')
  }

  function onLoginFailure(error: Error) {
    console.error(error)
    navigate('/')
  }

  const { handleLogInCallback } = useOauth2Flow({ onLoginSuccess, onLoginFailure })

  useEffect(() => {
    handleLogInCallback()
  }, [handleLogInCallback])

  return (
    <Layout.Stack justify="center" align="center" style={{ margin: 'auto', height: '100vh' }}>
      <Text variant="heading-sm-bold">Authentication in progress...</Text>
      <SpinnerWheel size="48" />
    </Layout.Stack>
  )
}

export default LoginThroughBIPCallback
