import React from 'react';
import { Card, Text } from '@loadsmart/miranda-react'
import { StyledCardDiv } from './MobileNotSupported.styles';

const MobileNotSupported: React.FC = () => {
    return (
        <StyledCardDiv>
            <Card>
                <Card.Title>Mobile Not Supported</Card.Title>
                <Card.Body>
                    <Text>Sorry, this page is not supported on mobile devices.</Text>
                </Card.Body>
            </Card>
        </StyledCardDiv>
    );
};

export default MobileNotSupported;