import { HttpClientConfig, type HttpClientInterface } from 'core/infra/http/HttpClient'

import {
  mapApiToUserLogin,
  mapTokenApiToUserLogin,
  RawLogin,
  RawUserLogin,
  TokenRequest,
  TokenResponse
} from './LoginMapper'

export type AccountRepositoryDependencies = {
  httpClient: HttpClientInterface
}

export class AccountRepository {
  private httpClient: HttpClientInterface

  constructor({ httpClient }: AccountRepositoryDependencies) {
    this.httpClient = httpClient
  }

  async login(email: string, password: string, captchaToken: string, config?: HttpClientConfig) {
    const endpoint = '/login'
    const payload = { email, password, captchaToken }

    const { data } = await this.httpClient.post<RawLogin, RawUserLogin>(endpoint, payload, config)
    try {
      return mapApiToUserLogin(data)
    } catch (error) {
      throw new Error('Failed to map API to UserLogin')
    }
  }

  async token(auth_code: string, code_verifier: string, config?: HttpClientConfig) {
    const endpoint = '/token'
    const payload = { auth_code, code_verifier }

    const { data } =  await this.httpClient.post<TokenRequest, TokenResponse>(endpoint, payload, config)

    try {
      return mapTokenApiToUserLogin(data)
    } catch (error) {
      throw new Error('Failed to map Token API to UserLogin')
    }
  }
}
