import styled from 'styled-components'
import { Dropdown } from '@loadsmart/miranda-react'

export const StyledHeaderDiv = styled('div')`
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 100;
  border-bottom: 1px solid rgba(230, 237, 242, 0.8);
  height: 40px;
`

export const StyledDropDown = styled(Dropdown)`
  margin-left: 1em;
`


export const StyledActionsDiv = styled('div')`
  margin-top: -18px;
`
