import { Text, } from '@loadsmart/miranda-react'

/*import { PiMegaphoneBold } from "react-icons/pi";*/

import { StyledReportHeaderDiv, StyledReportTitleDiv, /*StyledReportFeedbackDiv*/ } from './ReportHeader.styles'
import { RequestType } from 'managed-analytics/domain/RequestType';

interface ReportHeaderProps {
    toggleZendeskDialog: (requestType: RequestType) => void;
    reportName: string;
}

const ReportHeaderComponent: React.FC<ReportHeaderProps> = ({ /*toggleZendeskDialog,*/ reportName }) => {
    return (
        <StyledReportHeaderDiv>
            <StyledReportTitleDiv>
                <Text variant="body-lg">
                    <p>{reportName}</p>
                </Text>
            </StyledReportTitleDiv>
            {/*<StyledReportFeedbackDiv>
                <Text variant="button-md" color="color-primary-100" onClick={() => toggleZendeskDialog(RequestType.NewRequest)}>
                <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <PiMegaphoneBold style={{ fontSize: '1.4em', marginRight: '0.2em' }} />
                    <p style={{ margin: 0 }}>Give your feedback</p>
                </div>
                </Text>
            </StyledReportFeedbackDiv>*/}
        </StyledReportHeaderDiv>
    );
}

export default ReportHeaderComponent;
