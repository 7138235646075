import { Text, } from '@loadsmart/miranda-react'

/*import { PiMegaphoneBold } from "react-icons/pi";*/

import { StyledReportHeaderDiv, StyledReportTitleDiv, /*StyledReportFeedbackDiv*/ } from './ReportHeader.styles'

interface ReportHeaderProps {
    reportName: string;
}

const ReportHeaderComponent: React.FC<ReportHeaderProps> = ({ /*toggleZendeskDialog,*/ reportName }) => {
    return (
        <StyledReportHeaderDiv>
            <StyledReportTitleDiv>
                <Text variant="body-lg">
                    <p>{reportName}</p>
                </Text>
            </StyledReportTitleDiv>
        </StyledReportHeaderDiv>
    );
}

export default ReportHeaderComponent;
