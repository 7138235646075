import {
  compareAsc as compareAscFn,
  compareDesc as compareDescFn,
  differenceInHours as differenceInHoursFn,
  differenceInMinutes as differenceInMinutesFn,
  differenceInWeeks as differenceInWeeksFn,
  format as formatFn,
  formatISO as formatISOFn,
  isEqual as isEqualFn,
  isFuture as isFutureFn,
  isPast as isPastFn,
  isThisWeek as isThisWeekFn,
  isToday as isTodayFn,
  isWithinInterval as isWithinIntervalFn,
  parseISO as parseISOFn,
} from 'date-fns'

export { parse } from 'date-fns'

/**
 * Compare the two dates and return 1 if the first date is after the second, -1
 * if the first date is before the second or 0 if dates are equal.
 */
export function compareAsc(dateLeft: Date | number, dateRight: Date | number): number {
  return compareAscFn(dateLeft, dateRight)
}

/**
 * Compare the two dates and return -1 if the first date is after the second, 1
 * if the first date is before the second or 0 if dates are equal.
 */
export function compareDesc(dateLeft: Date | number, dateRight: Date | number): number {
  return compareDescFn(dateLeft, dateRight)
}

/**
 * Transforms a string or null into a Date or Undefined
 */
export function createDate(date?: string | null): Date | undefined {
  return date ? new Date(date) : undefined
}

/**
 * Get the difference in hours between 2 dates
 */
export function differenceInHours(dateLeft: Date, dateRight: Date) {
  return differenceInHoursFn(dateLeft, dateRight)
}

/**
 * Get the absolute difference in minutes between 2 dates
 */
export function differenceInMinutes(dateLeft: Date, dateRight: Date) {
  return Math.abs(differenceInMinutesFn(dateLeft, dateRight))
}

/**
 * Get the number of full weeks between two dates. The dates order doesn't matter.
 */
export function differenceInWeeks(dateLeft: Date | number, dateRight: Date | number): number {
  // The order matters on date-fns, if the dateLeft is lower than the dateRight,
  // it will return a negative number, even negative zero (-0) is returned.
  // This turns the API usage really confusing, so a Math.abs is being applied
  return Math.abs(differenceInWeeksFn(dateLeft, dateRight))
}

/**
 * Format date
 */
export function formatDate(date: Date, format: string): string {
  return formatFn(date, format)
}

/**
 * It formats a Date to ISO String
 */
export function formatISO(date: Date): string {
  return formatISOFn(date)
}

/**
 * Determine if a provided value is a Date or not
 */
export function isDate(value?: unknown): value is Date {
  return value instanceof Date
}

/**
 * Determine if the provided date do not have a time part
 */
export function isDateOnly(date: string): boolean {
  return /^\d{4}-\d{2}-\d{2}$/.test(date)
}

/**
 * Are the given dates equal?
 */
export function isEqual(dateLeft: Date | number, dateRight: Date | number): boolean {
  return isEqualFn(dateLeft, dateRight)
}

/**
 * Is the given date in the future?
 */
export function isFuture(date: Date | number): boolean {
  return isFutureFn(date)
}

/**
 * Is the given date in the past?
 */
export function isPast(date: Date | number): boolean {
  return isPastFn(date)
}

/**
 * Is the given date in the same week as the current date?
 */
export function isThisWeek(date: Date | number): boolean {
  return isThisWeekFn(date)
}

/**
 * Is the given date today?
 */
export function isToday(date: Date | number): boolean {
  return isTodayFn(date)
}

/**
 * It formats a String ISO to Date
 */
export function parseISO(date: string): Date {
  return parseISOFn(date)
}

/**
 * Is the given date within the interval? (Including start and end)
 */
export function isWithinInterval(date: Date, { start, end }: { start: Date; end: Date }): boolean {
  return isWithinIntervalFn(date, { start, end })
}

export const formats = {
  shortdate: 'EEE, MMM dd',
  twentyfour: 'HH:mm',
}
