import { BASE_API_URL } from 'core/infra/environment'
import { HttpClient } from 'core/infra/http/HttpClient'
import { SentryWrapper } from 'core/infra/sentry/SentryWrapper'
import { PBIRepository } from 'managed-analytics/infra/PBIRepository'

export type GetPBIReportsFactoryDependencies = {
  pbiRepository: PBIRepository
}

export function getPBIReportsFactory({ pbiRepository }: GetPBIReportsFactoryDependencies) {
  return async function (config?: { signal?: AbortSignal }) {
    try {
      const token = await pbiRepository.getReports(config)
      return token
    } catch (error) {
      SentryWrapper.log(error as Error)
      throw error
    }
  }
}

export function getPBIReports(config?: { signal?: AbortSignal }) {
  const httpClient = new HttpClient(BASE_API_URL)
  const accountRepository = new PBIRepository({ httpClient })

  const get = getPBIReportsFactory({ pbiRepository: accountRepository })

  return get(config)
}
