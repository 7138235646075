import React from 'react';
import { useState } from 'react'
import { Dropdown } from '@loadsmart/miranda-react';
import { Integration } from 'managed-analytics/domain/Integration';
import { Layout, SpinnerWheel, Text , Tooltip, Tag} from '@loadsmart/miranda-react'


import {
    StyledPageContainerDiv,
    StyledIntegrationdWrapper,
    StyledTable,
    StyledTableHead,
    StyledTableBody,
    StyledTableColumn, 
    StyledTableColumnName,
    StyledTableRow,
    StyledIntegrationsTitleDiv,
    StyledTableContainer,
    StyledDropDownTimeZone
  } from './Integration.styles'

interface IntegrationProps {
    integrations: Integration[];
    isLoadingIntegrations: boolean;
}

interface TimeZoneSelectorProps {
  selectedTimeZone: string;
  onTimeZoneChange: (newTimeZone: string) => void;
}

const TimeZoneSelector: React.FC<TimeZoneSelectorProps> = ({ selectedTimeZone, onTimeZoneChange }) => {
  const timeZones = ['UTC', 'EST', 'CST', 'PST'];
  return (
    <StyledDropDownTimeZone size="default">
      <Dropdown.Trigger>
        {selectedTimeZone ? selectedTimeZone : 'Select Timezone'}
      </Dropdown.Trigger>
      <Dropdown.Menu>
        {timeZones.map((tz) => (
          <Dropdown.Item key={tz} onClick={() => onTimeZoneChange(tz)}>
            {tz}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </StyledDropDownTimeZone>
  );
};

const formatDateTime = (dateTimeString: string | null | undefined, timeZone: string): string => {
  if (!dateTimeString) {
    return '-';
  }

  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    timeZone,
  };

  const dateTime = new Date(`${dateTimeString}Z`);
  return dateTime.toLocaleString('en-US', options).replace(/[\u200E\u200F]/g, '').replace(/\//g, '-');
};

const getMonitoringDatetime = (integrations: Integration[], selectedTimeZone: string): string => {
  if (integrations.length === 0) {
    return 'N/A';
  }
  const recentDatetime = Math.max(
    ...integrations
      .filter((integration) => !isNaN(Date.parse(integration.monitoringDatetime)))
      .map((integration) => new Date(`${integration.monitoringDatetime}Z`).getTime())
  );

  if (!isNaN(recentDatetime)) {
    return new Date(recentDatetime).toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: 'numeric',
      minute: 'numeric',
      timeZone: selectedTimeZone,
    });
  }

  return 'N/A';
};

const truncateText = (text: string | null, maxLength: number): string => {
  if (text === null || text === undefined) {
    return ''; 
  }

  return text.length > maxLength ? `${text.slice(0, maxLength -3)}...` : text;
};

const IntegrationComponent: React.FC<IntegrationProps> = ({ integrations, isLoadingIntegrations }) => {
  const handleTimeZoneChange = (timeZone:string) => {
      setSelectedTimeZone(timeZone);
    };
  const [selectedTimeZone, setSelectedTimeZone] = useState('UTC');
  return (
      <StyledIntegrationdWrapper>
          
      <StyledTableContainer>
      <StyledIntegrationsTitleDiv>
        <div style={{ flex: 1 }}>
          <Text color="color-neutral-100" variant="heading-md-bold">
            Integrations Overview
          </Text>
        </div>
          <Tooltip trigger="hover" message="Last sync with the integrations monitoring tool"><Text color="color-primary-60" variant="body-md-bold">
          <Text color="color-neutral-80" variant="body-md">
            Last refresh
          </Text>{getMonitoringDatetime(integrations, selectedTimeZone)}
          </Text></Tooltip>
          <TimeZoneSelector
            selectedTimeZone={selectedTimeZone}
            onTimeZoneChange={handleTimeZoneChange}
          />
      </StyledIntegrationsTitleDiv>
      { isLoadingIntegrations && (
        <StyledPageContainerDiv style={{ height: '70vh' }}>
          <Layout.Group align="center" justify="center" style={{ flex: 1 }}>
            <SpinnerWheel size="32px" />
          </Layout.Group>
        </StyledPageContainerDiv>
      )}
      {integrations.length > 0 && !isLoadingIntegrations && (
      <StyledTable>
        
          <StyledTableHead>
            <StyledTableRow>
              <StyledTableColumnName><Tooltip placement="bottom" trigger="hover" message="Name that identifies the integration"><Text variant="heading-sm-bold">Name</Text></Tooltip></StyledTableColumnName>
              <StyledTableColumnName><Tooltip placement="bottom" trigger="hover" message="Integration type, can be SFTP, Email or Spreadsheet"><Text variant="heading-sm-bold">Type</Text></Tooltip></StyledTableColumnName>
              <StyledTableColumnName><Tooltip placement="bottom" trigger="hover" message="Use given for the integrated data"><Text variant="heading-sm-bold">Usage</Text></Tooltip></StyledTableColumnName>
              <StyledTableColumnName><Tooltip placement="bottom" trigger="hover" message="Schedule in which the integration is triggered"><Text variant="heading-sm-bold">Schedule 
                <Text variant="body-sm" style={{ marginLeft: '5px' }}>
                  (UTC)
                </Text>
              </Text></Tooltip></StyledTableColumnName>
              <StyledTableColumnName><Tooltip placement="bottom" trigger="hover" message="Expected date and time of the most recent trigger"><Text variant="heading-sm-bold">Trigger {selectedTimeZone && (
                <Text variant="body-sm" style={{ marginLeft: '5px' }}>
                  ({selectedTimeZone})
                </Text>
              )}</Text></Tooltip></StyledTableColumnName>
                <StyledTableColumnName><Tooltip placement="bottom" trigger="hover" message="Deadline for the execution considering running time"><Text variant="heading-sm-bold">Deadline{selectedTimeZone && (
                <Text variant="body-sm" style={{ marginLeft: '5px' }}>
                  ({selectedTimeZone})
                </Text>
              )}</Text></Tooltip></StyledTableColumnName>
                <StyledTableColumnName><Tooltip placement="bottom" trigger="hover" message="Date and time of the last successfull integration"><Text variant="heading-sm-bold">Last Run{selectedTimeZone && (
                <Text variant="body-sm" style={{ marginLeft: '5px' }}>
                  ({selectedTimeZone})
                </Text>
              )}</Text></Tooltip></StyledTableColumnName>
              <StyledTableColumnName><Tooltip placement="bottom" trigger="hover" message="Integration status, can be On time or Disrupt"><Text variant="heading-sm-bold">Status</Text></Tooltip></StyledTableColumnName>
            </StyledTableRow>
          </StyledTableHead>
        
          <StyledTableBody>
          {integrations.map((integration, index) => (
            <StyledTableRow key={index}>
              <StyledTableColumn><Tooltip trigger="hover" placement="bottom" message={integration.name}><Text variant="body-md">{truncateText(integration.name, 23)}</Text></Tooltip></StyledTableColumn>
              <StyledTableColumn><Text variant="body-md">{integration.type}</Text></StyledTableColumn>
              <StyledTableColumn><Text variant="body-md">{integration.usage}</Text></StyledTableColumn>
              <StyledTableColumn><Tooltip trigger="hover" placement="bottom" message={integration.cronDescription}><Text variant="body-md">{truncateText(integration.cronDescription, 23)}</Text></Tooltip></StyledTableColumn>
              <StyledTableColumn><Text variant="body-md">{formatDateTime(integration.executionSchedule, selectedTimeZone)}</Text></StyledTableColumn>
              <StyledTableColumn><Text variant="body-md">{formatDateTime(integration.executionDeadline, selectedTimeZone)}</Text></StyledTableColumn>
              <StyledTableColumn><Text variant="body-md">{formatDateTime(integration.lastExecutionDatetime, selectedTimeZone)}</Text></StyledTableColumn>
              <StyledTableColumn>{integration.status === 'Disrupt' ? (
                <Tag variant="danger">{integration.status}</Tag>
              ) : (
                <Tag variant="success">{integration.status}</Tag>
              )}</StyledTableColumn>
            </StyledTableRow>
          ))}
          
          </StyledTableBody>
    
        </StyledTable>
      )} 
      {!isLoadingIntegrations && integrations.length === 0 && (
        <Text variant="body-md-bold">No integrations to show</Text>
      )}
      </StyledTableContainer>        
  </StyledIntegrationdWrapper>
  );
};

export default IntegrationComponent;