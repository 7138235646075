import { useState } from 'react'

import { type UserLogin } from 'auth/domain/UserLogin'
import { userLogin } from 'auth/use-cases/userLogin'
import { RequestStatus } from 'core/domain/Request'

export function useLogin() {
  const [accessToken, setAccessToken] = useState<UserLogin | undefined>()
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Initial)

  const isLoading = status === RequestStatus.Pending

  async function login(email: string, password: string, captchaToken: string, config?: AbortController) {
    setStatus(RequestStatus.Pending)

    try {
      const token = await userLogin(email, password, captchaToken, config)

      setAccessToken(token)
      setStatus(RequestStatus.Done)

      return token
    } catch (loginError) {
      if (config?.signal.aborted) return

      setStatus(RequestStatus.Error)
      setAccessToken(undefined)

      throw loginError
    }
  }

  return {
    isLoading,
    status,
    accessToken,
    login,
  }
}
