import { type UserLogin } from 'auth/domain/UserLogin'

export type RawUserLogin = {
  jwt_token: string
  user: string
  captchaToken: string
}

export type RawLogin = {
  email: string
  password: string
}

export type TokenRequest = {
  auth_code: string
  code_verifier: string
}

export type TokenResponse = {
  access_token: string
  refresh_token: string
  user: string
}

export function mapApiToUserLogin(userLogin: RawUserLogin): UserLogin {
  return {
    accessToken: userLogin.jwt_token,
    userLogin: userLogin.user,
    captchaToken: userLogin.captchaToken
  }
}

export function mapTokenApiToUserLogin(tokenResponse: TokenResponse): UserLogin {
  return {
    accessToken: tokenResponse.access_token,
    userLogin: tokenResponse.user,
    captchaToken: "",
  }
}
