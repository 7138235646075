import styled from 'styled-components'

export const StyledReportHeaderDiv = styled('div')`
  background-color: rgba(var(--m-color-background-primary, 252, 252, 255), 1);
  position: sticky;
  top: 0;
  height: 60px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(193, 206, 217, 0.8);
`

export const StyledReportTitleDiv = styled('div')`
  flex: 1;
  text-align: left;
  margin-left: 35px;
`;

export const StyledReportFeedbackDiv = styled('div')`
  flex: 1;
  text-align: right;
  margin-right: 35px;
`;
