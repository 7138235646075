import { useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import useOauth2Flow from 'auth/ui/hooks/useOauth2'

const LoginThroughBIP = () => {
  const { logIn, isLoggedIn } = useOauth2Flow()
  const navigate = useNavigate()

  useEffect(() => {
    async function redirect() {
      const isLogged = await isLoggedIn()
      if (isLogged) {
        navigate('/analytics')
      } else {
        logIn()
      }
    }

    redirect()
  }, [])

  return null
}

export default LoginThroughBIP
