import styled from 'styled-components'

export const StyledPBIEmbedWrapper = styled.div`
  display: flex;
  flex: 1;
  iframe {
    border: none;
  }
  .pbi-report-class {
    display: flex;
    flex: 1;
  }
`

export const StyledPageContainerDiv = styled.div`
  display: flex;
  height: 100vh;
  width: 100%;
`