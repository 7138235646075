import styled from 'styled-components'

import { Button } from '@loadsmart/miranda-react'

export const StyledInput = styled('input')`
  width: 93%;
  height: 2.5em;
  border: 1px solid rgb(150, 150, 150);
  border-radius: 3px;
  margin-bottom: 1.5em;
  margin-top: 0.3em;
  padding-left: 5%;
`

export const StyledLoginButton = styled(Button)`
  width: 100%;
  height: 3em;
  margin-bottom: 1em;
`

export const StyledReCAPTCHAWrapper = styled.div`
  width: 100%;
  margin-bottom: 1.2em;
  display: flex;
  justify-content: center;
`;