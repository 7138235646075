import React, { useRef } from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models, Report } from 'powerbi-client';
import { Layout, SpinnerWheel } from '@loadsmart/miranda-react'

import {
    StyledPBIEmbedWrapper,
    StyledPageContainerDiv
  } from './Report.styles'

interface ReportComponentProps {
  groupId: string;
  reportId: string;
  accessToken : string | undefined;
  isReportLoading : boolean;
}

const ReportComponent: React.FC<ReportComponentProps> = ({ groupId, reportId, accessToken, isReportLoading }) => {

  const currentReport = useRef<Report>();

  return (
    <StyledPBIEmbedWrapper>
    { isReportLoading &&(
        <StyledPageContainerDiv style={{ height: '70vh' }}>
            <Layout.Group align="center" justify="center" style={{ flex: 1 }}>
            <SpinnerWheel size="32px" />
            </Layout.Group>
        </StyledPageContainerDiv>
    )}
    { !isReportLoading && (
    <PowerBIEmbed
        embedConfig={{
            accessToken: accessToken,
            groupId: groupId,
            id: reportId,
            tokenType: models.TokenType.Embed,
            type: 'report',
            settings: {
              panes: {
                  pageNavigation: {
                      visible: true,
                      position: models.PageNavigationPosition.Left
                  },
              }
            }
        }}
            cssClassName="pbi-report-class"
            getEmbeddedComponent={embeddedReport => {
            currentReport.current = embeddedReport as Report
        }}
    />
    )}
    </StyledPBIEmbedWrapper>
  );
};

export default ReportComponent;