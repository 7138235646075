import styled from 'styled-components'

import { Dropdown } from '@loadsmart/miranda-react'

export const StyledPageContainerDiv = styled.div`
  display: flex;
  height: 100vh;
`

export const StyledSideMenuDiv = styled.div`
  width: 15%;
  background-color: #003333;
  position: fixed;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 0 8px 8px 0;
  transition: margin 0.1s;
  z-index: 1000;
  &.collapsed {
    margin-left: -11%;
    transition: margin 0.1s;
  }
`

export const StyledLSLogoImg = styled.img`
  margin: auto;
  width: 80%;
  margin-top: 1em;
  margin-left: 10%;
`

export const StyledSubTitleDiv = styled.div`
  color: #50768a;
  width: 80%;
  margin-left: 10%;
  margin-top: 3em;
`

export const StyledDropDown = styled(Dropdown)`
  width: 80%;
  margin-left: 10%;
  margin-top: 1em;
  background-color: #555;

  .Dropdown__Trigger {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .Dropdown__Trigger span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1; /* Ensure it takes up available space without growing */
  }
`;


export const StyledDropDownTimeZone = styled(Dropdown)`
  width: 10%;
  margin-left: 2%;
`

export const StyledActionDiv = styled.div`
  color: #fbd709;
  margin-left: 10%;
  margin-top: 0.8em;
  cursor: pointer; 
`

export const StyledReportWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 15%;
  transition: width 0.1s;
  &.collapsed {
    transition: width 0.1s;
    padding-left: 4%;
  }
`
