import { BASE_API_URL } from 'core/infra/environment'
import { HttpClient } from 'core/infra/http/HttpClient'
import { SentryWrapper } from 'core/infra/sentry/SentryWrapper'
import { IntegrationRepository } from 'managed-analytics/infra/IntegrationRepository'

export type GetIntegrationFactoryDependencies = {
  integrationRepository: IntegrationRepository
}

export function getIntegrationsFactory({ integrationRepository }: GetIntegrationFactoryDependencies) {
  return async function (config?: { signal?: AbortSignal }) {
    try {
      const token = await integrationRepository.getIntegration(config)

      return token
    } catch (error) {
      SentryWrapper.log(error as Error)
      throw error
    }
  }
}

export function getIntegrations(config?: { signal?: AbortSignal }) {
  const httpClient = new HttpClient(BASE_API_URL)
  const accountRepository = new IntegrationRepository({ httpClient })

  const get = getIntegrationsFactory({ integrationRepository: accountRepository })

  return get(config)
}
