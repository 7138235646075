import { BrowserRouter } from 'react-router-dom'

import '@loadsmart/miranda-react/dist/styles/variables.css'
import './index.css'

import { SettingsProvider } from '@loadsmart/react-frontend-settings'
import { SettingsValue } from '@loadsmart/react-frontend-settings/dist/context/provider'

import { BASE_API_URL } from 'core/infra/environment'
import { HttpClient } from 'core/infra/http/HttpClient'
import { useInitApplication } from 'core/ui/hooks/useInitApplication'
import Router from 'core/ui/Router'

async function getSettings(): Promise<SettingsValue> {
  const httpClient = new HttpClient(BASE_API_URL)
  const { data } = await httpClient.get<SettingsValue>('/fe-settings')
  return data
}

const App = () => {
  useInitApplication()

  return (
    <SettingsProvider getSettings={getSettings} options={{ autoUpdate: false }}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </SettingsProvider>
  )
}

export default App
