import { TOKEN_STORAGE_USER_KEY, LOGIN_STORAGE_USER } from 'auth/domain/User'
import { type UserLogin } from 'auth/domain/UserLogin'
import { AccountRepository } from 'auth/infra/AccountRepository'
import { BASE_API_URL } from 'core/infra/environment'
import { HttpClient } from 'core/infra/http/HttpClient'
import { SentryWrapper } from 'core/infra/sentry/SentryWrapper'
import { LocalStorageClient } from 'core/infra/storage/LocalStorageClient'
import { StorageInterface } from 'core/infra/storage/StorageInterface'

export type StorageRepository = StorageInterface

export type UserLoginFactoryDependencies = {
  accountRepository: AccountRepository
  storageRepository: StorageRepository
}

function setUserToken(login: UserLogin, storageRepository: StorageRepository) {
  storageRepository.set(TOKEN_STORAGE_USER_KEY, login.accessToken)
}

function setUserLogin(login: UserLogin, storageRepository: StorageRepository) {
  storageRepository.set(LOGIN_STORAGE_USER, login.userLogin)
}

export function userLoginFactory({
  accountRepository,
  storageRepository,
}: UserLoginFactoryDependencies) {
  return async function (email: string, password: string, captchaToken: string, config?: { signal?: AbortSignal }) {
    try {
      const login = await accountRepository.login(email, password, captchaToken, config)

      setUserToken(login, storageRepository)
      setUserLogin(login, storageRepository)
      return login
    } catch (error) {
      SentryWrapper.log(error as Error)
      throw error
    }
  }
}

export function userLogin(email: string, password: string, captchaToken: string, config?: { signal?: AbortSignal }) {
  const httpClient = new HttpClient(BASE_API_URL)
  const accountRepository = new AccountRepository({ httpClient })
  const storageRepository = new LocalStorageClient()

  const login = userLoginFactory({ accountRepository, storageRepository })
  return login(email, password, captchaToken, config)
}

export function tokenFactory({
  accountRepository,
  storageRepository,
}: UserLoginFactoryDependencies) {
  return async function (auth_code: string, code_verifier: string, config?: { signal?: AbortSignal }) {
    try {
      const userInfo = await accountRepository.token(auth_code, code_verifier, config)

      setUserToken(userInfo, storageRepository)
      setUserLogin(userInfo, storageRepository)
      return userInfo
    } catch (error) {
      SentryWrapper.log(error as Error)
      throw error
    }
  }
}

export function userToken(auth_code: string, code_verifier: string, config?: { signal?: AbortSignal }) {
  const httpClient = new HttpClient(BASE_API_URL)
  const accountRepository = new AccountRepository({ httpClient })
  const storageRepository = new LocalStorageClient()

  const token = tokenFactory({ accountRepository, storageRepository })
  return token(auth_code, code_verifier, config)
}
