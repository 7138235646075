import { type StorageInterface } from './StorageInterface'

export class LocalStorageClient implements StorageInterface {
  get<T>(key: string): T | undefined {
    const storedValue = window.localStorage.getItem(key)

    if (storedValue == null) {
      return undefined
    }

    try {
      return JSON.parse(storedValue)
    } catch {
      return storedValue as unknown as T
    }
  }

  set<T>(key: string, value: T): void {
    const jsonValue = JSON.stringify(value)
    window.localStorage.setItem(key, jsonValue)
  }

  delete(key: string): void {
    window.localStorage.removeItem(key)
  }

  clear(): void {
    window.localStorage.clear()
  }
}
