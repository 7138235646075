import { Button, Header as MirandaHeader, Dropdown, Text } from '@loadsmart/miranda-react'

import { StyledHeaderDiv, StyledDropDown, StyledActionsDiv } from './Header.styles'


const Header = () => {
  return (
    <StyledHeaderDiv>
      <MirandaHeader>
        <MirandaHeader.Content>
          <MirandaHeader.Title></MirandaHeader.Title>
        </MirandaHeader.Content>

        <MirandaHeader.Actions>
            <StyledActionsDiv>
              <Text variant="body-sm-bold" color="color-primary-60">
                Demo Shipper
              </Text>
              <StyledDropDown size="small">
                <Dropdown.Trigger aria-setsize={10}>
                  John Loes
                </Dropdown.Trigger>
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <Button size="small" variant="tertiary">
                      <p>Sign Out</p>
                    </Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </StyledDropDown>
            </StyledActionsDiv>

        </MirandaHeader.Actions>
      </MirandaHeader>
    </StyledHeaderDiv>
  )
}

export default Header
