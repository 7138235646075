export enum EnvType {
  Local = 'local',
  Qa = 'qa',
  Staging = 'staging',
  Sandbox = 'sandbox',
  Production = 'production',
}

export const BASE_API_URL = import.meta.env.VITE_BASE_API_URL
export const ENVIRONMENT = import.meta.env.VITE_ENVIRONMENT as EnvType
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN
export const RELEASE_VERSION = import.meta.env.VITE_RELEASE_VERSION
export const MIXPANEL_TOKEN = import.meta.env.VITE_MIXPANEL_TOKEN
export const CAPTCHA_WEBSITE_KEY = import.meta.env.VITE_CAPTCHA_WEBSITE_KEY
export const ANALYTICS_PORTAL_URL = import.meta.env.VITE_ANALYTICS_PORTAL_URL
export const BIP_URL = import.meta.env.VITE_BIP_URL
export const BIP_CLIENT_ID = import.meta.env.VITE_BIP_CLIENT_ID
export const __DEV__ = import.meta.env.MODE === 'development'
