import { useState } from 'react'

import { type UserLogin } from 'auth/domain/UserLogin'
import {userToken} from 'auth/use-cases/userLogin'
import { RequestStatus } from 'core/domain/Request'

export function useToken() {
  const [accessToken, setAccessToken] = useState<UserLogin | undefined>()
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Initial)

  const isLoading = status === RequestStatus.Pending

  async function fetchToken(auth_code: string, code_verifier: string, config?: AbortController) {
    setStatus(RequestStatus.Pending)

    try {
      const userLogin = await userToken(auth_code, code_verifier, config)

      setAccessToken(userLogin)
      setStatus(RequestStatus.Done)

      return userLogin
    } catch (loginError) {
      if (config?.signal.aborted) return

      setStatus(RequestStatus.Error)
      setAccessToken(undefined)

      throw loginError
    }
  }

  return {
    isLoading,
    status,
    accessToken,
    fetchToken,
  }
}
