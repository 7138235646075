import { useCallback, useState } from 'react'

import { RequestStatus } from 'core/domain/Request'
import { Integration } from 'managed-analytics/domain/Integration'
import { getIntegrations } from 'managed-analytics/use-cases/getIntegrations'

export function useIntegrations() {
  const [integrations, setIntegration] = useState<Array<Integration>>([])
  const [status, setStatus] = useState<RequestStatus>(RequestStatus.Initial)

  const isLoadingIntegrations = status === RequestStatus.Pending

  const getIntegration = useCallback(async (config?: AbortController) => {
    setStatus(RequestStatus.Pending)

    try {
      const integration = await getIntegrations(config)
      setIntegration(integration)
      setStatus(RequestStatus.Done)
    } catch (error) {
      if (config?.signal.aborted) return

      setStatus(RequestStatus.Error)
      setIntegration([])

      throw error
    }
  }, [])

  return {
    isLoadingIntegrations,
    status,
    integrations,
    getIntegration,
  }
}