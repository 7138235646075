import { HttpClientConfig, type HttpClientInterface } from 'core/infra/http/HttpClient'
import { RequestType } from '../domain/RequestType'

export type ZendeskRepositoryDependencies = {
  httpClient: HttpClientInterface
}

export class ZendeskRepository {
  private httpClient: HttpClientInterface

  constructor({ httpClient }: ZendeskRepositoryDependencies) {
    this.httpClient = httpClient
  }

  async requestZendesk(requestType: RequestType, name: string, company: string, email: string, phone: string, config?: HttpClientConfig) {
    const endpoint = '/zendesktryrequest'
    await this.httpClient.post(endpoint, { type: requestType, name: name, company: company, email: email, phone: phone }, config)
  }
}
