import { FormEvent, useRef, useState } from 'react';
import { Text } from '@loadsmart/miranda-react';
import { StyledInput, StyledLoginButton, StyledReCAPTCHAWrapper } from './LoginForm.styles';
import { CAPTCHA_WEBSITE_KEY } from 'core/infra/environment'
import ReCAPTCHA from 'react-google-recaptcha';

interface LoginFormProps {
  onSubmit: (email: string, password: string, captchaToken: string) => void;
  isLoading: boolean;
}

const LoginForm = ({ onSubmit, isLoading }: LoginFormProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const handleFormSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (captchaToken) {
      onSubmit(email, password, captchaToken);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
        setCaptchaToken(null);
      }
    } else {
      alert('Please complete the CAPTCHA');
    }
  };

  const handleCaptchaChange = (token: string | null) => {
    setCaptchaToken(token);
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <div>
        <Text>Email</Text>
        <div>
          <StyledInput
            type="text"
            id="email"
            value={email}
            disabled={isLoading}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>
      <div>
        <Text>Password</Text>
        <div>
          <StyledInput
            type="password"
            id="password"
            value={password}
            disabled={isLoading}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
      </div>
      <div>
        <StyledReCAPTCHAWrapper>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={CAPTCHA_WEBSITE_KEY}
            onChange={handleCaptchaChange}
          />
        </StyledReCAPTCHAWrapper>
      </div>
      <StyledLoginButton variant="primary" type="submit" loading={isLoading}>
        LOG IN
      </StyledLoginButton>
    </form>
  );
};

export default LoginForm;
