import styled from 'styled-components'

import { Dropdown } from '@loadsmart/miranda-react'

export const StyledPageContainerDiv = styled.div`
  display: flex;
  height: 100vh;
`

export const StyledLSLogoImg = styled.img`
  margin: auto;
  width: 80%;
  margin-top: 1em;
  margin-left: 10%;
`

export const StyledDropDown = styled(Dropdown)`
  width: 80%;
  margin-left: 10%;
  margin-top: 1em;
  background-color: #555;
`

export const StyledDropDownTimeZone = styled(Dropdown)`
  width: 10%;
  margin-left: 2%;
`

export const StyledActionDiv = styled.div`
  color: #fbd709;
  margin-left: 10%;
  margin-top: 0.8em;
  cursor: pointer; 
`

export const StyledIntegrationdWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  margin-top: 5vh;
`

export const StyledIntegrationsTitleDiv = styled.div`
  margin: 0 auto;
  height: 7vh;
  display: flex;
  align-items: center;
  justify-content: left;
  position: sticky;
  top: 0;
  z-index:10;
  background-color: white;
`

export const StyledScheduleDiv = styled.div`
overflow: hidden;
`

export const StyledTableContainer = styled.div`
  width: 95%;
  margin: 0 auto;
  overflow: auto;
  max-height: 80vh;
  align-items: center;
  justify-content: center;
`;

export const StyledTable = styled.table`
  border-collapse: collapse;
  border: 1px solid #eee;
  width: 100%; /* Take 100% width of the container */
`;

export const StyledTableHead = styled.thead`
  background-color: #f2f2f2;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
  position: sticky;
  top: 6.5vh;
  z-index: 1;
`;

export const StyledTableBody = styled.tbody`

`

export const StyledTableColumnName = styled.th`
  width: calc(100% / 8);
  background-color: #e6eaed;
  align-items: center;
  text-align: center;
`;

export const StyledTableRow = styled.tr`
  background-color: #f9f9f9;
  height: 2.5em;
  text-align: center;
  border: 1px solid #ddd;
`;

export const StyledTableColumn = styled.td`
 
`;