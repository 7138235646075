import { extraErrorDataIntegration } from '@sentry/integrations'
import * as Sentry from '@sentry/react'

import { ENVIRONMENT, EnvType, RELEASE_VERSION, SENTRY_DSN, __DEV__ } from 'core/infra/environment'
import { isEmpty } from 'core/utils/array'

const ALLOW_URLS: Record<EnvType, Array<string>> = {
  [EnvType.Local]: [],
  [EnvType.Qa]: ['ls-analytics-backend.qa.loadsmart.io'],
  [EnvType.Staging]: ['ls-analytics-backend.staging.loadsmart.io'],
  [EnvType.Sandbox]: ['ls-analytics-backend.sandbox.loadsmart.io'],
  [EnvType.Production]: ['ls-analytics-backend.loadsmart.io'],
}

const MAX_OBJECT_DEPTH = 5

type SentryConfig = {
  allowedUrls: Array<string>
}

class SentryWrapper {
  static init(config?: SentryConfig): void {
    const { allowedUrls = ALLOW_URLS[ENVIRONMENT] } = config ?? {}

    if (isEmpty(allowedUrls)) {
      console.debug(`skipping sentry: ${ENVIRONMENT} environment not allowed`)
      return
    }

    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENVIRONMENT,
      integrations: [
        new Sentry.BrowserTracing(),
        extraErrorDataIntegration({ depth: MAX_OBJECT_DEPTH }),
      ],
      release: RELEASE_VERSION,
    })
  }

  static log(error: Error): void {
    if (__DEV__) {
      console.error('Got sentry error', error)
      return
    }

    Sentry.captureException(error)
  }
}

export { SentryWrapper }
