import { type Integration } from 'managed-analytics/domain/Integration'

export type RawIntegration = {
    name: string;
    usage: string;
    type: string;
    cron_description: string;
    status: string;
    monitoring_datetime: string;
    execution_schedule: string;
    execution_deadline: string;
    last_execution_datetime: string;
    file_name: string;
    file_path: string;
  };

  export function mapApiToIntegration(integration: Array<RawIntegration>): Array<Integration> {
    const usageMappings: Record<string, string> = {
      feed_system: 'Feed System',
      feed_storage_report: 'Feed Storage/Report',
    };

    const statusMappings: Record<string, string> = {
      Success: 'On time',
      Fail: 'Disrupt',
    };

    const typeMappings: Record<string, string> = {
      sftp: 'SFTP',
      email: 'Email',
      spreadsheet: 'Spreadsheet',
    };

    return integration.map((integration) => ({
      name: integration.name,
      usage: usageMappings[integration.usage] || integration.usage,
      type: typeMappings[integration.type] || integration.type,
      cronDescription: integration.cron_description,
      status: statusMappings[integration.status] || integration.status,
      monitoringDatetime: integration.monitoring_datetime,
      executionSchedule: integration.execution_schedule,
      executionDeadline: integration.execution_deadline,
      lastExecutionDatetime: integration.last_execution_datetime,
      fileName: integration.file_name,
      filePath: integration.file_path,
    }));
  }
