import React from 'react'

import App from 'App'
import ReactDOM from 'react-dom/client'

function main() {
  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  )
}

main()
