import { BASE_API_URL } from 'core/infra/environment'
import { HttpClient } from 'core/infra/http/HttpClient'
import { SentryWrapper } from 'core/infra/sentry/SentryWrapper'
import { RequestType } from 'managed-analytics/domain/RequestType'
import { ZendeskRepository } from 'managed-analytics/infra/ZendeskRepository'

export type GetIntegrationFactoryDependencies = {
  zendeskRepository: ZendeskRepository
}

export function requestZendeskFactory({
  zendeskRepository,
}: GetIntegrationFactoryDependencies) {
  return async function (
    requestType: RequestType,
    description: string,
    reportName: string,
    config?: { signal?: AbortSignal }
  ) {
    try {
      await zendeskRepository.requestZendesk(requestType, description, reportName, config)
    } catch (error) {
      SentryWrapper.log(error as Error)
      throw error
    }
  }
}

export function requestZendesk(
  requestType: RequestType,
  description: string,
  reportName : string,
  config?: { signal?: AbortSignal }
) {
  const httpClient = new HttpClient(BASE_API_URL)

  const zendeskRepository = new ZendeskRepository({ httpClient })

  const get = requestZendeskFactory({ zendeskRepository })

  return get(requestType, reportName, description, config)
}
