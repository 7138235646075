import { type PBIToken, type PBIReport } from 'managed-analytics/domain/PBIReport'

import { GetTokenParams } from './PBIRepository'

export type RawPBIReport = {
  dataset_id: string
  group_id: string
  report_id: string
  report_name: string
  rls_filter: string
  customer_type: string
  role_name: string
}

export type RawPBIToken = {
  expires_in: string
  embed_token: string
}

export function mapApiToPBIReports(reports: Array<RawPBIReport>): Array<PBIReport> {
  return reports.map(report => ({
    datasetId: report.dataset_id,
    groupId: report.group_id,
    reportId: report.report_id,
    reportName: report.report_name,
    rlsFilter: report.rls_filter,
    customerType: report.customer_type,
    roleName: report.role_name
  }))
}

export function mapPBIReportToApi(report: PBIReport): GetTokenParams {
  return {
    dataset_id: report.datasetId,
    group_id: report.groupId,
    report_id: report.reportId,
    rls_filter: report.rlsFilter,
    customer_type: report.customerType,
    role_name: report.roleName
  }
}

export function mapApiToPBIToken({ embed_token, expires_in }: RawPBIToken): PBIToken {
  return {
    embedToken: embed_token,
    expiresIn: expires_in,
  }
}
