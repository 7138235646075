import LoginForm from './LoginForm'

import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Card } from '@loadsmart/miranda-react'
import { useLogin } from 'auth/ui/hooks/useLogin'
import { LocalStorageClient } from 'core/infra/storage/LocalStorageClient'
import { TOKEN_STORAGE_USER_KEY } from 'auth/domain/User'
import { StyledCardDiv } from './LoginPage.styles'

const LoginPage = () => {
  const [error, setError] = useState('')
  const navigate = useNavigate()

  // TODO: see why useAbortController usage is failing
  const abortController = useMemo(() => new AbortController(), [])

  const { isLoading, login } = useLogin()

  const storage = new LocalStorageClient();
  const userAccessToken = storage.get(TOKEN_STORAGE_USER_KEY);

  useEffect(() => {
    if (userAccessToken) navigate('/analytics')
  }, [navigate, userAccessToken])

  const handleLogin = useCallback(
    async (email: string, password: string, captchaToken: string) => {
      try {
        await login(email, password, captchaToken, abortController)
      } catch (error) {
        setError('Login failed. Please check your credentials.')
      }
    },
    [abortController, login]
  )

  return (
    <StyledCardDiv>
      <Card>
        <Card.Title>Sign in to Loadsmart's Managed Analytics</Card.Title>
        <Card.Divider></Card.Divider>
        <Card.Body>
          {error && <p>{error}</p>}
          <LoginForm onSubmit={handleLogin} isLoading={isLoading} />
        </Card.Body>
      </Card>
    </StyledCardDiv>
  )
}

export default LoginPage
