import { HttpClientConfig, type HttpClientInterface } from 'core/infra/http/HttpClient'
import { PBIReport } from 'managed-analytics/domain/PBIReport'

import {
  mapApiToPBIReports,
  mapApiToPBIToken,
  mapPBIReportToApi,
  RawPBIReport,
  RawPBIToken,
} from './PBIMapper'

export type GetTokenParams = Omit<RawPBIReport, RawPBIReport['report_name']>

export type PBIRepositoryDependencies = {
  httpClient: HttpClientInterface
}

export class PBIRepository {
  private httpClient: HttpClientInterface

  constructor({ httpClient }: PBIRepositoryDependencies) {
    this.httpClient = httpClient
  }

  async getReports(config?: HttpClientConfig) {
    const endpoint = '/pbi_reports'

    const { data } = await this.httpClient.get<Array<RawPBIReport>>(endpoint, config)

    try {
      return mapApiToPBIReports(data)
    } catch (error) {
      throw new Error('Failed to map API to PBI reports')
    }
  }

  async getPbiToken(report: PBIReport, config?: HttpClientConfig) {
    const endpoint = '/pbi_token'
    const { data } = await this.httpClient.post<GetTokenParams, RawPBIToken>(
      endpoint,
      mapPBIReportToApi(report),
      config
    )

    try {
      return mapApiToPBIToken(data)
    } catch (error) {
      throw new Error('Failed to map API to PBI token')
    }
  }
}
