import styled from 'styled-components'

import { Button } from '@loadsmart/miranda-react'

import image from 'assets/images/home_background.png'

export const StyledBackground = styled('div')`
  background-image: url(${image});
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 20px; // Add padding for some space around the elements
`

export const StyledTextButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 80px;
  width: 70%;
  margin-left: 5%;
`

export const StyledButtonContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const StyledLoginButton = styled(Button)`
  width: 20em;
  height: 3em;
  margin-right: 20px;
`

export const StyledLSLogo = styled('img')`
  width: 20%;
  align-self: flex-end;
  margin-left: auto; // Align to the right
  position: absolute;
  margin-bottom: 80px;
  margin-right: 1%;
`
export const StyledSigningInContainer = styled('div')`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
`
