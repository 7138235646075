import { useState } from 'react';
import { Dialog, Dropdown } from '@loadsmart/miranda-react';
import { RequestType } from 'managed-analytics/domain/RequestType';

function getRequestTypeText(requestType: RequestType) {
  switch (requestType) {
    case RequestType.NewRequest:
      return 'New request';
    case RequestType.IssueBug:
      return 'Issue/bug';
    case RequestType.Question:
      return 'Question';
  }
}

interface ZendeskDialogProps {
  open: boolean;
  requestType?: RequestType;
  disabled: boolean;
  onSendRequest: (description: string) => void;
  onCancelRequest: () => void;
  setRequestType: (requestType: RequestType) => void
}

const ZendeskDialog = ({
  open,
  requestType,
  disabled,
  onSendRequest,
  onCancelRequest,
  setRequestType
}: ZendeskDialogProps) => {
  const [description, setDescription] = useState<string>('');

  const handleSendRequest = async () => {
    onSendRequest(description);
    setDescription('');
  };

  const handleClose = () => {
    onCancelRequest();
    setDescription('');
  };

  const isDisabled = disabled || description.length <= 0;
  const requestTypeText = getRequestTypeText(requestType || RequestType.NewRequest);

  return (
    <Dialog open={open} size="small" variant="neutral" onClose={handleClose}>
      <Dialog.Header>
        <Dropdown>
            <Dropdown.Trigger>
              {requestTypeText ? requestTypeText : 'Select'}
            </Dropdown.Trigger>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setRequestType(RequestType.NewRequest)}>New Request</Dropdown.Item>
            <Dropdown.Item onClick={() => setRequestType(RequestType.IssueBug)}>Issue/Bug</Dropdown.Item>
            <Dropdown.Item onClick={() => setRequestType(RequestType.Question)}>Question</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Dialog.Close />
      </Dialog.Header>
      <Dialog.Body>
        Please describe the {requestTypeText.toLowerCase()}
        <div>
          <textarea
            value={description}
            onChange={({ target }) => setDescription(target.value)}
            rows={10}
            cols={40}
          />
        </div>
      </Dialog.Body>
      <Dialog.Footer></Dialog.Footer>
      <Dialog.Actions>
        <Dialog.ActionTertiary onClick={handleClose} disabled={isDisabled}>
          Cancel
        </Dialog.ActionTertiary>
        <Dialog.ActionPrimary onClick={handleSendRequest} disabled={isDisabled}>
          Send request
        </Dialog.ActionPrimary>
      </Dialog.Actions>
    </Dialog>
  );
};

export default ZendeskDialog;
