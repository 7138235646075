import { Text, SpinnerWheel } from '@loadsmart/miranda-react';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSettings } from "@loadsmart/react-frontend-settings";
import LsLogo from 'assets/images/ls_logo.png'

import {
  StyledBackground,
  StyledButtonContainer,
  StyledLoginButton,
  StyledLSLogo,
  StyledTextButtonContainer,
  StyledSigningInContainer
} from './HomePage.styles';
import { LocalStorageClient } from "core/infra/storage/LocalStorageClient";

const storage = new LocalStorageClient();

const HomePage = () => {
  const { isLoading, values: [isLoginThroughBIPEnabled] } = useSettings(["flags.ENABLE_LOGIN_THROUGH_BIP"]);
  const navigate = useNavigate();
  const [signingIn, setSigningIn] = useState<string | null>(null); // State to track the signing-in process

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isMobile = /Mobi/i.test(userAgent);

    if (isMobile) {
      navigate('/mobile-not-supported');
    }

    storage.clear();
  }, [navigate]);

  useEffect(() => {
    if (signingIn) {
      // Redirect after 3 seconds
      const redirectTimeout = setTimeout(() => {
        if (signingIn === 'bip') {
          navigate('/login');
        } else if (signingIn === 'default') {
          navigate('/login-lmt');
        }
      }, 3000);

      // Cleanup timeout on unmount
      return () => clearTimeout(redirectTimeout);
    }
  }, [signingIn, navigate]);

  // If signingIn is in progress, show the white background with message
  if (signingIn) {
    return (
      <StyledSigningInContainer>
        <SpinnerWheel size="sm" />
        <Text color="color-text-primary" variant="body-lg-bold">
         Signing in {signingIn === 'bip' ? 'As Shipper Guide' : ''}...
        </Text>
      </StyledSigningInContainer>
    );
  }

  if (isLoading || isLoginThroughBIPEnabled == null) {
    console.log('isLoading:', isLoading, 'isLoginThroughBIPEnabled:', isLoginThroughBIPEnabled);
  } else {
    console.log('loaded!');
  }

  return isLoading ? null :(
    <StyledBackground className="full-screen">
      <StyledTextButtonContainer>
        <Text color="color-background-primary" variant="hero-md-bold">
          Take your data analysis to the next level
        </Text>
        {isLoginThroughBIPEnabled ? (
          <StyledButtonContainer>
            <StyledLoginButton
              variant="primary"
              onClick={() => setSigningIn('default')}
            >
              SIGN IN
            </StyledLoginButton>
            <StyledLoginButton
              variant="primary"
              onClick={() => setSigningIn('bip')}
            >
              SIGN IN AS SHIPPER GUIDE
            </StyledLoginButton>
          </StyledButtonContainer>
        ) : (
          <StyledButtonContainer>
            <StyledLoginButton variant="primary" href="/login">
              SIGN IN
            </StyledLoginButton>
          </StyledButtonContainer>
        )}
      </StyledTextButtonContainer>
      <StyledLSLogo src={LsLogo} alt="LS Logo" />
    </StyledBackground>
  );
};

export default HomePage;
