export const enum RequestStatus {
  Initial = 'initial',
  Pending = 'pending',
  Done = 'done',
  Error = 'error',
}

export type Data<T> = {
  data: T
}
