import styled from 'styled-components'

export const StyledCardDiv = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 70%;
  margin: auto;
`
